import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { AdSenseAttributes, AdUnitType, GoogleAdsProviderService } from "../ads-service/google/google-ads-service";
import { HexakaiPlusSubscriptionService } from "../hexakai-plus-subscription/hexakai-plus-subscription-service";
import { ComponentDependencyTree } from "../component-dependency-tree/component-dependency-tree";
import { HexakaiPlusFeatures } from "../hexakai-plus-subscription/hexakai-plus-features";

@customElement('ad-unit')
export class AdUnitComponent extends LitElement {

    private clientService = ComponentDependencyTree.clientService;
    private hexakaiPlusService = ComponentDependencyTree.hexakaiPlusSubscriptionService;
    private adsEnabled = false;
    private adType = "bookAds";

    private static adCount = 0;
    private adId: number;

    @property({ type: String }) unit = AdUnitType.primaryHorizontal;
    @property({ type: String }) useHr = "false";

    createRenderRoot() {
        return this;
    }

    constructor() {
        super();

        // check if we should load ads
        this.hexakaiPlusService.isFeatureUnlocked(HexakaiPlusFeatures.noAds)
            .then(isNoAds => {
                this.adsEnabled = !isNoAds;
                this.requestUpdate();
            });

        AdUnitComponent.adCount ++;
        this.adId = AdUnitComponent.adCount;
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.adType = this.weightedRandomSelection(
            this.clientService.getConfig().ads.adUnitViewPercentages
        );


        this.requestUpdate();
        this.style.maxWidth = "100%";
    }

    render() {
        if (!this.adsEnabled) {
            return html``;
        }

        const useHr = this.useHr.toLowerCase() === "true";

        let adUnit = html``;
        switch (this.adType) {
            case "bookAds":
                adUnit = html`<book-ad-unit .unit="${this.unit}"></book-ad-unit>`;
                break;
            case "googleAds":
                adUnit = html`<google-ad-unit .unit="${this.unit}"></google-ad-unit>`;
                break;
            default:
                adUnit = html`<infolinks-ad-unit .unit="${this.unit}"></infolinks-ad-unit>`;
                break;
        }

        const adHrClass = `ad-hr-${this.adId}`;

        return html`
            <style>
                .ad-component-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 100%;
                }

                book-ad-unit {
                    display: contents;
                }

                ${useHr ? "" : html`
                    .${adHrClass} {
                        display: none;
                    }    
                `}
            </style>
            <hr class="${adHrClass}" />
            <div class="ad-component-container">
                ${adUnit}
            </div>
            <hr class="${adHrClass}" />
        `;
    }

    private weightedRandomSelection<T extends Record<string, number>>(config: T): keyof T {
        // Get total weight sum
        const totalWeight = Object.values(config).reduce((sum, weight) => sum + weight, 0);
    
        // Normalize weights
        const weights = Object.entries(config).map(([key, weight]) => ({
            key: key as keyof T,
            weight: weight
        }));
    
        // Generate a random number between 0 and 1
        const random = Math.round(Math.random() * totalWeight);
    
        // Iterate over the normalized weights and select the item
        let cumulative = 0;
        for (const { key, weight } of weights) {
            cumulative += weight;
            if (random < cumulative) {
                return key;
            }
        }
    
        // Fallback, should never reach here
        return weights[weights.length - 1].key;
    }
    
}