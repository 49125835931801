import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { AdSenseAttributes, AdUnitType, GoogleAdsProviderService } from "../ads-service/google/google-ads-service";
import { InfolinksAdProviderService } from "../ads-service/infolinks/infolinks-ads-service";

@customElement('infolinks-ad-unit')
export class InfoLinksAdUnitComponent extends LitElement {

    @property({ type: String }) unit = AdUnitType.primaryHorizontal;

    private scriptAttached = false;

    createRenderRoot() {
        return this;
    }

    render() {
        if (!this.scriptAttached) {
            this.attachScript();
            return ``;
        }

        return html`<input type="hidden" name="IL_IN_ARTICLE">`;
    }

    private async attachScript(): Promise<void> {
        await InfolinksAdProviderService.attachScript();
        this.scriptAttached = true;
        this.requestUpdate();
    }
   
}