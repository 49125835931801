import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { AdSenseAttributes, AdUnitType, GoogleAdsProviderService } from "../ads-service/google/google-ads-service";
import { AUTHOR_BOOK_ENTRIES } from "../ads-service/author-books/author-books-config";

@customElement('book-ad-unit')
export class BookAdUnitComponent extends LitElement {

    @property({ type: String }) unit = AdUnitType.primaryHorizontal;
    @property({ type: String }) book = AUTHOR_BOOK_ENTRIES[
        Math.floor(Math.random() * AUTHOR_BOOK_ENTRIES.length)
    ];

    private COMMON_CSS = css`
        .book-ad-title {
            margin-top: 0;
            margin-bottom: 0em;
        }

        .book-ad-container {
            text-align: left;
            text-decoration: none;
            display: block;

            border: 1.5px solid var(--body-color);
            padding: 1em;

            background-color: var(--underlay-color);
            max-width: 100%;
        }

        .book-ad-container:hover {
            cursor: pointer;
            background-color: var(--input-border-color);
        }

        .book-img {
            height: 100%; 
            margin-right: 1em;
            margin-bottom: 0.5em;
            border: 1px solid var(--body-color);
        }

        .book-box {
            margin-top: 0px;
            margin-bottom: 0px;
            list-style-position: inside; /* Optional: Moves the bullet inside the list item's box */
        }

        .book-description-container {
            display: flex;
            flex-direction: row;

            height: 100%;

            font-size: 0.9em;
        }

        .book-description {
            -webkit-mask-image: linear-gradient(180deg, var(--underlay-color) 90%, transparent);
            overflow: hidden;
        }

        .author-message {
            font-size: 90%;
        }

        @media screen and (max-width: 900px) {
            .author-message {
                display: none;
            }
        }
    `;

    private PRIMARY_HORIZONTAL_CSS = css`
        ${this.COMMON_CSS}

        .book-ad-container {
            aspect-ratio: 3.5;
            max-height: 300px;
        }
    `;

    private FIXED_SMALL_CSS = css`
        ${this.COMMON_CSS}

        .book-ad-container {
            width: 450px;
            aspect-ratio: 3.5;
            max-height: 250px;

            font-size: 0.85em;
        }

        .author-message {
            display: none;
        }

        .book-description {
            -webkit-mask-image: linear-gradient(180deg, var(--underlay-color) 80%, transparent);
        }
    `;

    createRenderRoot() {
        return this;
    }

    render() {
        let styles = css``;
        switch (this.unit) {
            case AdUnitType.fixedSmall:
                styles = this.FIXED_SMALL_CSS;
                break;
            default:
                styles = this.PRIMARY_HORIZONTAL_CSS;
                break;
        }

        return html`
            <style>
                ${styles}
            </style>

            <a class="book-ad-container" href="${this.book.amazonUrl}" target="_blank">
                <div class="book-description-container">
                    <img class="book-img" src="${this.book.coverImgUrl}" />
                    <div class="book-description">
                        <h3 class="book-ad-title">${this.book.title}</h3>
                        <p class="author-message">This book is written by Brandon Quinn, creator of Hexakai. Please consider adding this to your bookshelf!</p>
                        <hr class="author-message" />
                        ${this.book.description}
                    </div>
                </div>
            </a>

        `;
    }
}