/**
 * This loads non-critical components
 */
import './components/loading-display';
import './components/hex-cell';
import './components/hexakai-board';
import './components/modal-layover';
import './components/hexakai-game-settings';
import './components/game-rules';
import './components/info-box';
import './components/app-rules';
import './components/game-intro';
import './components/welcome-modal';
import './components/achievements-view';
import './components/img-smart';
import './components/styled-button';
import './components/daily-puzzles-view';
import './components/blog-view';
import './components/non-navigator-share';
import './components/difficulty-levels-text';
import './components/table-of-contents';
import './components/tutorial-view';
import './components/gameplay-view';
import './components/tabbed-box';
import './components/settings-panel';
import './components/game-complete';
import './components/hexakai-game';
import './components/account-login';
import './components/hexakai-plus-manage';
import './components/google-ad-unit';
import './components/hexakai-plus-description';
import './components/book-ad-unit';
import './components/ad-unit';
import './components/infolinks-ad-unit';