import { LitElement } from "lit";
import { customElement, property } from "lit/decorators";
import { AdSenseAttributes, GoogleAdsProviderService } from "../ads-service/google/google-ads-service";

@customElement('google-ad-unit')
export class GoogleAdUnitComponent extends LitElement {

    @property({ type: Object }) unit: AdSenseAttributes = GoogleAdsProviderService.AD_UNITS.fixedSmall;

    private scriptAttached = false;

    createRenderRoot() {
        return this;
    }

    render() {
        if (!this.scriptAttached) {
            this.attachScript();
            this.scriptAttached = true;
        }

        return GoogleAdsProviderService.getAdMarkup(this.unit);
    }

    private attachScript() {
        GoogleAdsProviderService.attachGoogleAdsScript(this.unit);
        setTimeout(() => {
            // TODO: hacky, find better way of encapsulating this
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
        }, 150);
    }
}